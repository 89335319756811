exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-glossary-term-js": () => import("./../../../src/templates/GlossaryTerm.js" /* webpackChunkName: "component---src-templates-glossary-term-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

